<template>
  <div>
    <Header />
    <Menu />
    <main class="inner-content">
      <router-view></router-view>
    </main>
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'main-layout',
  components: {
    Header: () => import('./header'),
    Menu: () => import('./menu'),
    Footer: () => import('./footer')
  }
}
</script>

<style></style>
